import React from 'react';
import { Paper, Grid,  List, ListItemText, Divider, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useAtomValue } from 'jotai/utils';
import { interestingBlocksFetch } from '../modules/interestingBlocks';
import { timezoneAtom } from '../modules/time';
import { ListItemButton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    timePaper: {
        margin: "auto",
        maxWidth: 350,
    },
    left: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.primary,
    },
    eventsContainer: {
      marginTop: 20,
    },
  }),
);

export const BlocksOfInterest: React.FC = () => {
  const classes = useStyles();
  const blockEvents = useAtomValue(interestingBlocksFetch);
  const timeZone = useAtomValue(timezoneAtom);

    return (
      <div className={classes.root}>
        <Grid container className={classes.eventsContainer}>
          <Grid xs={12} item >
              <Paper className={`${classes.left}  ${classes.timePaper}`}>
                <Typography variant="h6">Some blocks of interest...</Typography>
                
                <Paper elevation={0}>
                  <List>
                    {
                      blockEvents.map((event) => {
                        return (
                          <div>
                            <ListItemButton
                              href={`${event.source}`}
                              target="_blank"
                              component="a"
                              sx={{
                                padding: 0,
                              }}
                            >
                              <ListItemText
                                primary={`#${event.blockNumber} - ${event.description}`}
                                secondary={event.blockDate.date.toLocaleString("en-US", {timeZone})}
                              />
                            </ListItemButton>
                            {/* <Divider /> */}
                          </div>
                        )
                      })
                    }
                  </List>
                </Paper>
              </Paper>
          </Grid>
        </Grid>
        
      </div>
    )
}
