import { atom } from "jotai";
import { getCalculatedDate } from "../util/timeTools";

// let miaCycleLength = 2100;

export const interestingBlocks = [
  {
    blockNumber: 41296,
    description: "MIA Cycle 7 ends",
    source:
      "https://docs.citycoins.co/citycoins-core-protocol/stacking-citycoins",
  },
  {
    blockNumber: 41649,
    description: "NYC Cycle 2 begins",
    source:
      "https://docs.citycoins.co/citycoins-core-protocol/stacking-citycoins",
  },
];

const interestingBlocksAtom = atom(interestingBlocks);

export const interestingBlocksFetch = atom(async (get) => {
  let blockEvents = [];
  const events = get(interestingBlocksAtom);
  for (let i = 0; i < events.length; i++) {
    let date = await await getCalculatedDate(events[i].blockNumber);
    blockEvents.push({
      blockNumber: events[i].blockNumber,
      blockDate: date,
      description: events[i].description,
      source: events[i].source,
    });
  }

  return blockEvents;
});
