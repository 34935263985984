import { BottomNavigation } from '@material-ui/core';
import { Provider } from 'jotai';
import React, { Suspense } from 'react';
import './App.css';
import { BlocksOfInterest } from './components/blockOfInterest';
import { ConverterContainer } from './components/converterContainer';
import { TopBar } from './components/topBar';

function App() {
  return (
    <div className="App">
      <Provider>
        <TopBar />
        <ConverterContainer/>
        <Suspense fallback="Loading">
          <BlocksOfInterest />
        </Suspense>

        <BottomNavigation>
        </BottomNavigation>
        DISCLAIMER: The dates displayed are based on average block time which is around 10 minutes per block. The dates provided are approximations.
      </Provider>
    </div>
  );
}

export default App;
